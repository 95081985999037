import React, { useState, useEffect } from 'react';
import Sidebar from '../../Components/Includes/Sidebar'
import img1 from '../../assets/images/thumnail-1.jpg';
import img2 from '../../assets/images/thumnail-2.jpg';
import img3 from '../../assets/images/thumnail-3.jpg';
import { authHttps as makeHttps } from "../../auth/AuthUser";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import video1 from '../../assets/videos/video-1.mp4';
import { formatDistanceToNow } from 'date-fns';
import { Download, PlayArrow, Shuffle } from '@mui/icons-material';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom';
import Header from '../../Components/Includes/Header';
import SidebarIcon from '../../Components/Includes/SidebarIcon';
import { VideoRoute } from '../../utils/routeUtils';

function LikedVideos() {

    const http = makeHttps();

    const [allVideos, setallVideos] = useState([]);
    const [loadingvideoss, setloadingvideoss] = useState(true);
    // sidebar
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(280);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const fetchAllVideo = async () => {
        try {
            const response = await http.post('user/allLikedVideo',);
            console.log('tesy', response);
            if (response.data && response.data.data) {
                setallVideos(response.data.data);
            } else {
                console.error('Invalid data structure received from the API:', response.data);
            }
        } catch (error) {
            console.error('Error fetching video categories:', error);
        } finally {
            setloadingvideoss(false);
        }
    };

    useEffect(() => {
        fetchAllVideo();
    }, []);


    const navigate = useNavigate();

    const viewVideo = async (videoId, catid) => {
        localStorage.setItem('videoId', videoId);
        localStorage.setItem('catid', catid);
        await http.post('user/addView', { 'videoId': videoId });
        navigate('/watch');
    };


    const TimeAgo = ({ date }) => {
        // Assuming `date` is a string in the format 'YYYY-MM-DDTHH:mm:ss.sssZ'
        const parsedDate = new Date(date);

        const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true });

        return <span>{timeAgo}</span>;
    };



    const formatViewsCount = (count) => {
        if (count < 1000) {
            return count.toString();
        } else if (count < 1000000) {
            return `${(count / 1000).toFixed(1)}K`;
        } else {
            return `${(count / 1000000).toFixed(1)}M`;
        }
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarCollapsed(!isSidebarCollapsed);
        setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
    };
    return (
        <>
            <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
                <div className='container'>
                    <div className='row'>
                        {/* {loadingvideoss ? (
                            <p>Loading Video...</p>
                        ) : allVideos.length && allVideos.length > 0 ? (

                            allVideos.map((video, index) => (
                                <div className='col-lg-8' onClick={() => viewVideo(video.video.videoId, video.video.playlistCategory)}>
                                    <div className='video-box-single-h with-action'>
                                        <Card >
                                            <CardContent>
                                                <div className='row g-2'>
                                                    <div className='col-4'>
                                                        <div className='video-box'>
                                                            <video className='w-100' poster={video.video.thumbnail}>
                                                                <source src={video.video.url} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        </div>
                                                    </div>
                                                    <div className='col-8'>
                                                        <div className='content-box'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <Typography variant="h5" component="div" className='v-tiltle'>
                                                                        {video.video.title}
                                                                    </Typography>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        <a href='/channel' className='channel-name'>{video.channel.name}</a>
                                                                        <a href='/watch' className='views-time'>{formatViewsCount(video.video.view)} views <span>.</span> {<TimeAgo date={video.video.createdAt} />}</a>
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-1 d-none'>
                                                        <div className="dropdown text-end">
                                                            <a href="#" className="d-block caret-none link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                                                </svg>
                                                            </a>
                                                            <ul className="dropdown-menu ">
                                                                <li><a className="dropdown-item" href="/login">Download</a></li>
                                                                <li><a className="dropdown-item" href="#">Share</a></li>
                                                                <li><a className="dropdown-item" href="#">Profile</a></li>
                                                                <li><hr className="dropdown-divider" /></li>
                                                                <li><a className="dropdown-item" href="#">Remove Video</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No Video available.</p>
                        )} */}
                    </div>

                </div>
                <section className="">
                    <div className="section__title mb-3">
                        <h2 className='h4'><span className="bi bi-file-play text-theme"></span> Liked videos</h2>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-4 d-none'>
                                <div className='card bg-dark'>
                                    <div className='left-bx'>
                                        <div className='video-box'>
                                            <Link href='#' className=' d-block'>
                                                <CardMedia
                                                    sx={{ height: 200 }}
                                                    image={img3}
                                                    title="green iguana"
                                                    className='rounded-4 mb-4 liked-img-box'
                                                />
                                            </Link>
                                        </div>
                                        <h3 className='mt-4'>Liked videos</h3>
                                        <p style={{ color: '#fff', fontSize: '14px' }}>11 videos No views Last updated on Mar 31, 2024</p>
                                        <button className='btn-donload'>
                                            <Download />
                                        </button>
                                        <div className='bnt-grp mt-3'>
                                            <button className='btn-play'>
                                                <PlayArrow /> Play All
                                            </button>
                                            <button className='btn-shuffle'>
                                                <Shuffle />  Shuffle
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='card p-2 bg-dark'>


                                    {loadingvideoss ? (
                                        <p>Loading Video...</p>
                                    ) : allVideos.length && allVideos.length > 0 ? (

                                        allVideos.map((video, index) => (

                                            <div className="video-box-single-h" >
                                                <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-bhp9pd-MuiPaper-root-MuiCard-root">
                                                    <div className="MuiCardContent-root css-46bh2p-MuiCardContent-root">
                                                        <div className="row g-2">
                                                            <div className="col-3">
                                                                <div className="video-box">
                                                                    <Link to={VideoRoute(video.video.uniqId)}>
                                                                        <div>

                                                                            <video
                                                                                className="MuiCardMedia-root MuiCardMedia-media w-100 css-e6k82g-MuiCardMedia-root"
                                                                                alt="Video Poster"
                                                                                poster={video.video.thumbnail}
                                                                            >
                                                                                <source
                                                                                    src={video.video.url}
                                                                                    className="w-100"
                                                                                    type="video/mp4"
                                                                                />
                                                                            </video>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-9">
                                                                <div className="content-box">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="MuiTypography-root MuiTypography-h5 v-tiltle css-ag7rrr-MuiTypography-root">
                                                                                <Link to={VideoRoute(video.video.uniqId)}>
                                                                                    {video.video.title}
                                                                                </Link>
                                                                            </div>
                                                                            <p className="MuiTypography-root MuiTypography-body2 css-r40f8v-MuiTypography-root">
                                                                                <Link to={VideoRoute(video.video.uniqId)} className="views-time" >
                                                                                    <small style={{ fontSize: '16px' }}>{video.channel.name}</small> <span>.</span>{formatViewsCount(video.video.view)} views <span>.</span> <span>{<TimeAgo date={video.video.createdAt} />}</span>
                                                                                </Link>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No Video available.</p>
                                    )}



                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default LikedVideos