/*################################################################################*/

export const LIBRARY_ID = 209851;
export const ACCESS_KEY = 'f4b2ebad-4cbb-4808-a4ba688f9000-1578-4dc5';
export const VIDEO_CDN = 'https://vz-6a29694a-43b.b-cdn.net/';
export const APP_URL = 'https://kbtube.com/';

// dev
// export const APP_URL = 'http://localhost:3000/';

/*################################################################################*/

// Production Environment
export const API_URL =   'https://shaliniinfo.in';
// export const API_URL =   'http://localhost:3300';


// Dev Environment
// export const API_URL = 'https://odishatechnosolutions.com';

/*################################################################################*/
