import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom'
import { getToken } from "../../auth/AuthUser";
import { getUser } from "../../auth/AuthUser";
import { authHttps } from "../../auth/AuthUser";
import { Subscriptions, AccountBoxOutlined, Home, Whatshot, Tag, Watch, AppShortcutTwoTone } from "@mui/icons-material";

function Sidebar() {

  let userData = getUser();
  let token = getToken();


  const http = authHttps();
  const [chanenl, setChanneel] = useState(null);



  const fetchChannel = async () => {
    try {
      const response = await http.post('user/myChanel');
      if (response.data && response.data.status == 1) {
        setChanneel(true);
      } else {
        setChanneel(false);
      }
    } catch (error) {
      console.error('Error fetching Channel:', error);
    }
  };


  useEffect(() => {
    fetchChannel();
  }, []);






  return (
    <div className="popular__events__left">
      <div className="popular__events__head">
        <ul>
          <li>
            <NavLink to="/" activeClassName="active" className={`nav-link`} aria-current="page" data-bs-dismiss="offcanvas">
              <span className='me-1 bi'>
                <Home />
              </span>
              <span className='sidebar-title'>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/shorts" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='me-1 bi'><AppShortcutTwoTone /></span>
              <span className='sidebar-title'> Shorts </span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/liked-videos" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='me-1 bi'><i className="bi bi-hand-thumbs-up-fill"></i></span>
              <span className='sidebar-title'> Liked videos </span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/watch-later-videos" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='me-1 bi'><i className="bi bi-clock-fill"></i></span>
              <span className='sidebar-title'> Watch later </span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/history-videos" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='me-1 bi'><i className="bi bi-clock-fill"></i></span>
              <span className='sidebar-title'> Watch History </span>
            </NavLink>
          </li>

          {userData !== null ?
            <li>
              <NavLink activeClassName="active" to="/feed/subscriptions" className={`nav-link`} data-bs-dismiss="offcanvas" >
                <span className='me-1 bi'>
                  <Subscriptions />
                </span>
                <span className='sidebar-title'>Subscriptions</span>
              </NavLink>
            </li> : null}
        </ul>
      </div>
      <hr/>
      <div className="popular__events__head">
        <h5 className='text-start'>You <span className="bi bi-chevron-right"></span></h5>
        <ul>
          {userData == null ?
            <li>
              <NavLink
                href="URL:void(0)"
                data-bs-toggle="modal"
                data-bs-target="#signInPin" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
                <span className="me-1 bi bi-person-circle"></span>
                <span>Sign in</span>
              </NavLink>
            </li>
            :
            <li>
              {chanenl == true && (
                <NavLink to="/channel" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
                  <span className="me-1 bi">
                    <AccountBoxOutlined />
                  </span>
                  <span>Your Channel</span>
                </NavLink>
              )}
              {chanenl == false && (
                <NavLink to="/create-channel" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
                  <span className="me-1 bi bi-film"></span>
                  <span>Create Channel</span>
                </NavLink>

              )}
            </li>
          }
        </ul>
      </div>
      <hr/>
      <div className="popular__events__head">
        <h5 className='text-start'>Explore</h5>
        <ul>
          <li>
            <NavLink to="/trending" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='me-1 bi'>
                <Whatshot />
              </span>
              <span className='sidebar-title'> Trending </span>
            </NavLink>
          </li>

        </ul>
      </div>

      <div className="popular__events__head">
        <ul>
          <li>
            <NavLink to="/disclaimer" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='sidebar-title'> Disclaimer </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='sidebar-title'> About </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact-us" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='sidebar-title'> Contact Us </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/support" target='new' activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='sidebar-title'> Support </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/account-delete-reason" target='new' activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='sidebar-title'> Account Delete Reason </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/privacy-policy" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='sidebar-title'> Privacy Policy </span>
            </NavLink>
          </li>

        </ul>
      </div>

    </div>



  )
}

export default Sidebar