import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Includes/Sidebar";
import Header from "../Components/Includes/Header";
import SidebarIcon from "../Components/Includes/SidebarIcon";
function About() {
    // sidebar
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(280);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarCollapsed(!isSidebarCollapsed);
        setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
    };
    return (
        <>
            <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
                <div className="content-registration pt-3 bg-dark ">
                    <div className="container">
                        <div className="p-1">
                            <div className="p-1">
                                <div className='row g-3' >
                                    <div className="col-lg-12">
                                        <h1>KBTube - About</h1>
                                        <p>
                                            KB TUBE represents the pinnacle of video streaming innovation, combining an extensive content library, user-friendly interface, and advanced features to deliver an unparalleled entertainment experience under the esteemed banner of KB Group. Whether seeking entertainment, education, or inspiration, KB TUBE stands ready to captivate audiences and redefine the way they consume digital media.
                                        </p>
                                        <p>
                                            KB TUBE is a cutting-edge video streaming application designed to provide a seamless and engaging content consumption experience. Our primary focus is on empowering content creators and fostering a vibrant community. We have implemented a unique monetization strategy that sets us apart from other video streaming platforms.
                                        </p>
                                        <p>
                                            To unlock monetization features on KB TUBE, creators need to achieve a milestone of 150 subscribers and accumulate 500 watch hours. This approach ensures that creators who are actively building a community and generating meaningful content can start benefiting from their efforts.
                                        </p>
                                        <p>
                                            One standout feature of KB TUBE is our commitment to handling copyright issues responsibly. In the event of a copyright strike, we have a non-punitive approach. Instead of suspending the entire channel, we only remove the specific copyrighted video in question. This approach allows creators to learn from the experience without jeopardizing the entire channel's existence.
                                        </p>
                                        <p>
                                            In terms of revenue sharing, KB TUBE takes pride in offering one of the most favorable arrangements for content creators. We prioritize maximizing the earnings for our creators compared to other video streaming applications. Our revenue-sharing model is designed to reward creators for their hard work and dedication, fostering a mutually beneficial relationship between KB TUBE and its content contributors.
                                        </p>
                                        <p>
                                            As a platform, KB TUBE recognizes the importance of nurturing a supportive and thriving community. We encourage creators to focus on content creation and community engagement while we handle the technical and administrative aspects. By choosing KB TUBE, content creators can look forward to a monetization process that is fair, transparent, and designed to amplify their success in the dynamic world of online content creation. Join KB TUBE today and unlock the full potential of your creative journey.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default About