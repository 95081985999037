// VideoUtills.js


// export const thumbnail_size = { width: 2048, height: 1152 }; // for high resolution
export const THUMB_SIZE = { width: 1280, height: 720 };

export const VIEW_COUNT = (count) => {
    if (count < 1000) {
        return count.toString();
    } else if (count < 1000000) {
        return `${(count / 1000).toFixed(1)}K`;
    } else {
        return `${(count / 1000000).toFixed(1)}M`;
    }
};


