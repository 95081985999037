import React, { useEffect, useState } from "react";
import { https as makeHttps } from "../auth/AuthUser";
import { Link, useNavigate } from 'react-router-dom';
import Header from "../Components/Includes/Header";



function Support() {
    const [isOpenForm, setIsOpenForm] = useState(false);


    const [registerStatus, setRegisterStatus] = useState(null);

    const [name, setName] = useState('');
    const [emails, setEmails] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setPassword1] = useState('');
    const [message, setPassword2] = useState('');


    const [namee, setNamee] = useState('');
    const [emaile, setEmaile] = useState('');
    const [phonee, setPhonee] = useState('');
    const [subject_error, setPassworde1] = useState('');
    const [message_error, setPassworde2] = useState('');
    const [loginerror, setLoginerror] = useState('');
    const [loginsuccess, setLoginsuccess] = useState('');
    const [respStatus, setRespStatus] = useState(null);


    const navigate = useNavigate();
    const toggleForm = () => {
        setIsOpenForm(!isOpenForm);
    };

    const http = makeHttps();



    const handleRegister = async () => {

        try {
            const responses = await http.post('user/support', {
                name: name,
                email: emails,
                phone: phone,
                subject: subject,
                message: message,
            });

            if (responses.data && responses.data.status === 1) {

                setRegisterStatus(responses.data.status);

            } else {

                console.error('Error during Support Form:', responses.data.message);

                setNamee('');
                setEmaile('');
                setPhonee('');
                setPassworde1('');
                setPassworde2('');


                if (Array.isArray(responses.data.message)) {
                    responses.data.message.map((category) => {

                        if (category.param === "name") {
                            setNamee(category.msg);

                        }
                        if (category.param === "email") {
                            setEmaile(category.msg);
                        }
                        if (category.param === "phone") {
                            setPhonee(category.msg);
                        }
                        if (category.param === "subject") {
                            setPassworde1(category.msg);
                        }
                        if (category.param === "message") {
                            setPassworde2(category.msg);
                        }


                    });


                } else {
                    setNamee('');
                    setEmaile('');
                    setPhonee('');
                    setPassworde1('');
                    setPassworde2('');

                    if (responses.data.message === 'User exists') {
                        setEmaile(responses.data.message);
                    } else {
                        console.error(responses.data.message);
                        // alert(responses.data.message);
                    }
                }
            }
        }
        catch (error) {
            // Log the error to the console for debugging
            console.error('Error during Register:', error);

            // Display the server's error message to the user
            // alert(`Support failed. ${error.response.data.message}`);
        }
    };



    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const [showPassword2, setShowPassword2] = useState(false);

    const handlePasswordVisibility2 = () => {
        setShowPassword2((prevShowPassword2) => !prevShowPassword2);
    };

    const [showPassword3, setShowPassword3] = useState(false);

    const handlePasswordVisibility3 = () => {
        setShowPassword3((prevShowPassword3) => !prevShowPassword3);
    };

    return (

        <>
            <Header/>
            <div className="content-registration bg-dark">
                <div className="container">
                    <div className='row justify-content-center align-items-center' >
                        <div className='col-lg-4'>
                            <div className="content-top  mb-3">
                                <h2 class="fw-bolder text-center">
                                    <span >Support</span>
                                </h2>
                            </div>
                            <div className='rounded-4 shadow bg-gray login-form-box'>



                                {(registerStatus === 1) ?
                                    <div className="p-3 p-lg-5 text-center" onClick={() => setRegisterStatus(null)}>
                                        <div className="success-ico"><span className="bi bi-check-circle"></span></div>
                                        <h5 className="secondary-color">Enquiry Submited Successfully.</h5>
                                        <p>We will reach you soon.</p>
                                        <p>
                                            <Link to="/" className="text-decoration-none secondary-color"  >Home</Link>
                                        </p>
                                    </div>
                                    :
                                    <form action="#0" className={`p-3`}>
                                        <div className="form-floating mb-3">
                                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} id="name" placeholder="Enter your Name *" />
                                            <label for="Fname">Name</label>
                                            <div className="text-danger">{namee}</div>

                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type="email" className="form-control" value={emails} onChange={(e) => setEmails(e.target.value)} id="EmailId" placeholder="E.g., name@example.com *" />
                                            <label for="EmailId">Email address</label>
                                            <div className="text-danger">{emaile}</div>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type="tel" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} id="phone" placeholder="9876543210 *" />
                                            <label for="EmailId">Phone</label>
                                            <div className="text-danger">{phonee}</div>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type={showPassword2 ? 'text' : 'text'} className="form-control" value={subject} onChange={(e) => setPassword1(e.target.value)} id="Subject" placeholder="Enter Subject*" />
                                            <label for="Subject">Subject</label>
                                            <div className="text-danger">{subject_error}</div>

                                        </div>

                                        <div className="form-floating mb-3">
                                            <input type='text' className="form-control" value={message} onChange={(e) => setPassword2(e.target.value)} id="Message" placeholder="Enter Your Message*" />
                                            <label for="Message">Message</label>
                                            <div className="text-danger">{message_error}</div>

                                        </div>


                                        <Link href="URL:void(0)" className="w-100 mb-2  btn-lg cmn--btn  rounded-5" onClick={handleRegister} >
                                            <span>Submit</span>
                                        </Link>

                                    </form>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Support