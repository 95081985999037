import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom'
import { getToken } from "../../auth/AuthUser";
import { getUser } from "../../auth/AuthUser";
import { authHttps } from "../../auth/AuthUser";
import { Subscriptions, AccountBoxOutlined, Home, Whatshot, Tag, Watch, YouTube, TrendingFlat, AppShortcutTwoTone, VideoLibrarySharp, MovieFilterSharpIcon } from "@mui/icons-material";
function SidebarIcon() {
  let userData = getUser();
  let token = getToken();


  const http = authHttps();
  const [chanenl, setChanneel] = useState(null);



  const fetchChannel = async () => {
    try {
      const response = await http.post('user/myChanel');
      if (response.data && response.data.status == 1) {
        setChanneel(true);
      } else {
        setChanneel(false);
      }
    } catch (error) {
      console.error('Error fetching Channel:', error);
    }
  };


  useEffect(() => {
    fetchChannel();
  }, []);

  return (
    <div className="popular__events__left">
      <div className="popular__events__head">
        <ul>
          <li style={{display:'block', textAlign:'center'}}>
            <NavLink to="/" activeClassName="active" className={`nav-link`} aria-current="page" data-bs-dismiss="offcanvas">
              <span className='me-1 bi'>
                <Home />
              </span>
              <span className='sidebar-title'>
                 <small>Home</small>
              </span>
            </NavLink>
          </li>
          <li style={{display:'block', textAlign:'center'}}>
            <NavLink to="/shorts" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='me-1 bi'><AppShortcutTwoTone /></span>
              <span className='sidebar-title'>
                 <small>Short</small>
              </span>
            </NavLink>
          </li>

          <li style={{display:'block', textAlign:'center'}}>
            <NavLink to="/liked-videos" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='me-1 bi'> <Subscriptions /></span>
              <span className='sidebar-title'>
                 <small>Subscribe</small>
              </span>
            </NavLink>
          </li>

          <li style={{display:'block', textAlign:'center'}}>
            <NavLink to="/watch-later-videos" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='me-1 bi'><VideoLibrarySharp/></span>
              <span className='sidebar-title'>
                 <small style={{display:'block'}}>Trending</small>
              </span>
            </NavLink>
          </li>

          <li style={{display:'block', textAlign:'center'}}>
            <NavLink to="/watch-later-videos" activeClassName="active" className={`nav-link`} data-bs-dismiss="offcanvas" >
              <span className='me-1 bi'><YouTube/></span>
              <span className='sidebar-title'>
                 <small style={{display:'block'}}>You</small>
              </span>
            </NavLink>
          </li>

        </ul>
      </div>
    </div>
  )
}

export default SidebarIcon