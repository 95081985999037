import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../utils/routeUtils";


const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
}


const getUser = () => {

    const userString = localStorage.getItem('user');
    const user_details = JSON.parse(userString);
    return user_details;
}


const reactBaseUrl = () => {

    return 'http://localhost:3300/';
}

const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}

const checkLogin = () => {
    // const navigate = useNavigate();
    // const tokenString = localStorage.getItem('token');
    // const userToken = JSON.parse(tokenString);
    // if(!userToken){
    //     return <Navigate to="/login" replace={true} />;
    // }
}


const https = () => {
    return axios.create({
        baseURL: apiUrl,
        headers: {
            "content-type": "application/json"
        }
    });

}


const authHttps = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    return axios.create({
        baseURL: apiUrl,
        headers: {
            "content-type": "application/json",
            "authorization": userToken
        }
    });

}

export default function AuthUser() {
    const navigate = useNavigate();



    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());

    const saveToken = (user, token) => {

        localStorage.setItem('token', JSON.stringify(token))
        localStorage.setItem('user', JSON.stringify(user))

        setToken(token);
        setUser(user);
        navigate('/dashboard');


    }

    const http = axios.create({
        baseURL: apiUrl,
        headers: {
            "content-type": "application/json"
        }

    });


    return {
        setToken: saveToken,
        token,
        user,
        http,
        reactBaseUrl,
        authHttps
    }
}

export { getToken, getUser, logOut, https, checkLogin, authHttps }