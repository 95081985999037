import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Includes/Sidebar";
import Header from "../Components/Includes/Header";
import SidebarIcon from "../Components/Includes/SidebarIcon";
function ContactUs() {
    // sidebar
    const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(280);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
        setSidebarCollapsed(!isSidebarCollapsed);
        setSidebarWidth(isSidebarCollapsed ? 280 : 80); // Toggle sidebar width
    };
    return (
        <>
            <Header toggleSidebar={toggleSidebar} />
            <div className="col-xxl-2 col-xl-3 col-lg-3 display991" style={{ width: `${sidebarWidth}px` }}>
                {sidebarOpen ? (
                    <SidebarIcon onClick={toggleSidebar} />
                ) : (
                    <Sidebar onClick={toggleSidebar} />
                )}
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-9" style={{ width: isSidebarCollapsed ? '1266px' : 'calc(100% - 280px)' }}>
                <div className="content-registration pt-3 bg-dark ">
                    <div className="container">
                        <div className="p-1">
                            <div className="p-1">
                                <div className='row g-3' >
                                    <div className="col-lg-12">
                                        <h2 className="mb-2">Contact Us</h2>
                                        <p>Welcome to KB TUBE's Contact Page. If you have any questions, feedback, or concerns regarding our video streaming application, please feel free to reach out to us using the following channels:</p>
                                        <ol>
                                            <li><strong>Address:</strong> If you prefer traditional mail correspondence, you can send letters or packages to our headquarters at the following address:<br />
                                                KB TUBE Headquarters<br />
                                                4300 Biscayne Blvd Suite 203 Miami,<br />
                                                Florida 33137, US</li>

                                            <li><strong>Email:</strong> You can contact us via email at <a href="mailto:contact@kbtube.com">contact@kbtube.com</a> for general inquiries, partnership opportunities, or media inquiries. We strive to respond to all emails within 24-48 hours.</li>
                                            <li><strong>Contact Number:</strong> <a href="tel:+1 305-317-1806">+1 305-317-1806</a></li>

                                        </ol>
                                        <p>Thank you for choosing KB TUBE. We appreciate your support and look forward to serving you better.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ContactUs